//首页banner
import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Row, Col } from "antd"

import * as styles from "./index.module.scss"

export default function Banner() {
  const data = useStaticQuery(graphql`
    query aboutQuery {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
      bannerMobile: file(relativePath: { eq: "bannerMobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const image = getImage(data.banner.childImageSharp.gatsbyImageData)
  const bannerMobile = getImage(
    data.bannerMobile.childImageSharp.gatsbyImageData
  )

  return (
    <div className={styles.banner}>
      <Row>
        <Col md={24} sm={0} xs={0}>
          {/* pc */}
          {image && <GatsbyImage image={image} alt="banner" />}
          <div className={styles.text}>
            <h1>Yunstar，百万跨境卖家首选</h1>
            <h3>全球布局实现精准海外营销</h3>
          </div>
        </Col>
        <Col md={0} sm={24} xs={24}>
          {/* mobile */}
          {bannerMobile && <GatsbyImage image={bannerMobile} alt="banner" />}
          <div className={styles.textMobile}>
            <h1>
              Yunstar
              <br />
              百万跨境卖家首选
            </h1>
            <h3>全球布局实现精准海外营销</h3>
          </div>
        </Col>
      </Row>
    </div>
  )
}
