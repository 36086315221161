// 汇聚海外流量板块 (首页模块)
import React, { ReactNode } from "react"

import HeadLine from "@components/Headline"

import platformBg1 from "@images/platformBg1.png"
import platformBg2 from "@images/platformBg2.png"
import platformBg3 from "@images/platformBg3.png"
import platformBg4 from "@images/platformBg4.png"

import * as styles from "./index.module.scss"

interface IplatFormItem {
    children: ReactNode | string
    img: any
}

function PlatFormItem({ children, img }: IplatFormItem) {
    return <div className={styles.platform_item}>
        <img src={img} alt="" />
        <div className={styles.text}>
            {children}
        </div>
    </div>

}



export default function PlatForm() {
    return <div className={styles.platform}>
        <HeadLine>汇聚头部流量平台 </HeadLine>
        <div className={styles.second_title}>整合全球流量资源，助力中国企业迅速拓展海外市场</div>
        <div className={styles.main}>
            <PlatFormItem img={platformBg1}>月活用户29亿</PlatFormItem>
            <PlatFormItem img={platformBg2}>覆盖全球90%<br />网络用户</PlatFormItem>
            <PlatFormItem img={platformBg3}>每日播放量<br />10亿次</PlatFormItem>
            <PlatFormItem img={platformBg4}> 百亿次<br />月度搜索</PlatFormItem>
        </div>
    </ div>
}