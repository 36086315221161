// 全球链路解决方案（首页模块）
import React from "react"

import HeadLine from "@components/Headline"

import * as styles from "./index.module.scss"

import { Col, Row, Collapse } from "antd"
import { UpOutlined } from "@ant-design/icons"
// import { Link } from "gatsby"

const { Panel } = Collapse
const LinkRenderData = [
  {
    icon: "linkIcon1",
    bg: "linkBg1",
    text_show: "自动生成创意内容",
    text_hidden: "分析海外受众审美，一键自动生成多样化、差异化广告创意内容。",
  },
  {
    icon: "linkIcon2",
    bg: "linkBg2",
    text_show: "智能广告投放",
    text_hidden:
      "针对定向人群进行个性化广告投放，实现精准推送，动态优化广告预算，自动控制广告停启。",
  },
  {
    icon: "linkIcon3",
    bg: "linkBg3",
    text_show: "借鉴平台资历优势",
    text_hidden:
      "统筹链接多方资源，持续输入营销技术，优化营销策略，助力企业快速拓展海外市场。",
  },
  {
    icon: "linkIcon4",
    bg: "linkBg4",
    text_show: "业务数据分析",
    text_hidden:
      " 自动化数据分析，ROI、AOV等关键数据可视化呈现，最大限度发挥数据价值，为业务提升提供数据支撑。",
  },
  {
    icon: "linkIcon5",
    bg: "linkBg5",
    text_show: "风控系统",
    text_hidden: " 选品、广告、侵权风险预先管控，增效减损，安心经营。",
  },
  {
    icon: "linkIcon6",
    bg: "linkBg6",
    text_show: "物流保障",
    text_hidden:
      "甄选高质量服务商，实现配送轨迹实时速报，保障稳定时效，无忧销售。",
  },
  {
    icon: "linkIcon7",
    bg: "linkBg7",
    text_show: "售后服务",
    text_hidden:
      "为顾客提供售后保障服务，集中管理争议/拒付事件，远离店铺投诉。",
  },
]
function GetImg(name: string) {
  return require(`@images/${name}.png`).default
}

interface IMobileItem {
  bg: string
  text_show: string
  text_hidden: string
  icon: string
}
const MobileItem = ({ bg, text_show, text_hidden, icon }: IMobileItem) => {
  return (
    <div
      style={{
        backgroundImage: `url(${GetImg(bg + "_shadow")})`,
        backgroundSize: "100% auto",
      }}
      className={styles.mobile_item}
    >
      <div className={styles.icon}>
        <img src={GetImg(icon)} alt="" />
      </div>

      <Collapse
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <UpOutlined
            rotate={isActive ? 0 : 180}
            style={{ color: "#9396A3" }}
          />
        )}
      >
        <Panel
          header={<span style={{ color: "#fff" }}>{text_show}</span>}
          key="1"
        >
          <span style={{ color: "#fff" }}>{text_hidden}</span>
        </Panel>
      </Collapse>
    </div>
  )
}

export default function LinkSolution() {
  return (
    <div className={styles.linkSolution}>
      <div className={styles.main}>
        <div className={styles.title}>
          <HeadLine>全链路数字化营销</HeadLine>
        </div>
        <div className={styles.intro}>
          <div className={styles.intro_title}>
            构建一体化数字营销体系，实现消费者运营数字化闭环
          </div>
          <div className={styles.intro_content}>
            集程序化创意系统及智能广告投放系统于一体，凭借平台资历优势、深度数据分析、完善的风控体系及售后系统，真正帮商家做到降本、增收、避险，全方面为您的生意提供增长助力。
          </div>
        </div>
        <Row>
          <Col md={24} sm={0} xs={0}>
            {/* pc */}
            <div className={styles.effectBoxContainer}>
              {LinkRenderData.map((item, index) => {
                return (
                  <div className={styles.box} key={index}>
                    <img src={GetImg(item.bg)} alt="linkIcon" />
                    <div className={styles.mask}></div>
                    <div className={styles.slide}>
                      <img src={GetImg(item.icon)} alt="icon" />
                      <div className={styles.showText}>{item.text_show}</div>
                      <div className={styles.hiddenText}>
                        {item.text_hidden}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.link_footer}>
              <div className={styles.slide}>
                <img src={GetImg("linkBook")} alt="linkBook" /> Yunstar智库{" "}
                <span className={styles.hover_show}>
                  更全面的行业新知传递平台，更为通俗易懂的基础铺垫，更深度的进阶实操指南
                </span>
              </div>
            </div>
          </Col>
          <Col md={0} sm={24} xs={24}>
            <div style={{ marginTop: "40px" }}>
              {LinkRenderData.map((item, index) => (
                <MobileItem {...item} key={index} />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
